import React, {useState, useEffect} from 'react';
import {View, StyleSheet, ActivityIndicator} from 'react-native';
import ButtonIconHeader from '../../components/ButtonIconHeader';
import Theme from '../../style/Theme';
import {Colors,Routes} from '../../configs';
import ScrollableTab from '../../components/ScrollableTab';
import {getBottomSpace} from 'react-native-iphone-x-helper';
import Container from '../../components/Layout/Container';


import {api, helpers} from '../../import';
import {EVENT_RELOAD_PRESCRIPTIONS, EVENT_SHOW_MODAL_ACTION, EVENT_SHOW_MODAL_INFO} from '../../constants/Events';
import {
  PRESCRIPTION_REQUEST_STATUS_CREATED,
  PRESCRIPTION_REQUEST_STATUS_ACCEPTED,
  PRESCRIPTION_REQUEST_STATUS_IGNORED,
  PRESCRIPTION_REQUEST_STATUS_REJECTED,
  MEDICINES_PERMISSION,
} from '../../models/constants';
import locales_es from '../../locales/es';
import {showModalInfo} from '../../modules/ViewHelpers';
import {CommonStyles} from '../../styles/CommonStyles';
import PrescriptionsList from './../../components/PrescriptionsList';
import {ConsultsType, width} from '../../configs/Const';
import {useHistory} from "react-router-dom";
import {EventRegister} from "../../modules/EventRegister";

interface PrescriptionsRequestsProps {}

const PrescriptionsRequests = (props: PrescriptionsRequestsProps) => {
  const history = useHistory();
  const params = history.location.state;
  const [load, setLoad] = useState(false);
  const [pendingRequests, setPendingRequests] = useState(null);
  const [processedPrescriptions, setProcessedPrescriptions] = useState(null);
  const [prescriptionRequestId, setPrescriptionRequestId] = useState(
    params ? params.medicalPrescriptionRequestId : null,
  );

  const [nextPageUrlPending, setNextPageUrlPending] = useState('');
  const [refreshingPending, setRefreshingPending] = useState(false);
  const [totalPending, setTotalPending] = useState(0);

  const [nextPageUrlProcessed, setNextPageUrlProcessed] = useState('');
  const [refreshingProcessed, setRefreshingProcessed] = useState(false);
  const [totalProcessed, setTotalProcessed] = useState(0);

  const loadPendingRequests = (urlPage?: string) => {
    api
      .getMyPrescriptionsRequests({
        status: PRESCRIPTION_REQUEST_STATUS_CREATED, paginated: true
      }, urlPage)
      .then((res: any) => {
        setPendingRequests(pendingRequests ? pendingRequests.concat(res.data) : res.data);
        setNextPageUrlPending(res.next_page_url);
        setRefreshingPending(false);
        setTotalPending(res.total);
      })
      .catch((err: any) => {
        helpers.processError(err);
      });
  };

  const loadProcessedPrescriptions = (urlPage?: string) => {
    const processedStatus =
      PRESCRIPTION_REQUEST_STATUS_ACCEPTED +
      ',' +
      PRESCRIPTION_REQUEST_STATUS_IGNORED +
      ',' +
      PRESCRIPTION_REQUEST_STATUS_REJECTED;
    api
      .getMyPrescriptionsRequests({
        status: processedStatus, paginated: true
      }, urlPage)
      .then((res: any) => {
        setProcessedPrescriptions(processedPrescriptions ? processedPrescriptions.concat(res.data) : res.data);
        setNextPageUrlProcessed(res.next_page_url);
        setRefreshingProcessed(false);
        setTotalProcessed(res.total);
      })
      .catch((err: any) => {
        helpers.processError(err);
      });
  };

  const onLoadMorePendingPrescriptions = () => {
    if (nextPageUrlPending && !refreshingPending) {
      setRefreshingPending(true);
      loadPendingRequests(nextPageUrlPending);
    }
  };
  const onLoadMoreProcessedPrescriptions = () => {
    if (nextPageUrlProcessed && !refreshingProcessed) {
      setRefreshingProcessed(true);
      loadProcessedPrescriptions(nextPageUrlProcessed);
    }
  };

  const loadPermissions = () => {
    return new Promise((resolve) => {
      api.getPermissionsMe().then((res: any) => {
        resolve(res);
      }).catch((err: any) => {
        resolve([])
      })
    })
  }

  const confirmPermissions = () => {
    history.goBack();
  }

  useEffect(() => {
    loadPermissions().then((res: any) => {
      if (res && res.some((perm: any) => perm.tag === MEDICINES_PERMISSION)) {
        loadPendingRequests();
        loadProcessedPrescriptions();
        return;
      }
      EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
        title: locales_es.modalPermission.title,
        message: locales_es.modalPermission.description,
        actions: [{text: 'Aceptar', onPress: confirmPermissions, white: true}]
      });
    });
  }, [load]);

  useEffect(() => {
    const listener = EventRegister.addEventListener(
      EVENT_RELOAD_PRESCRIPTIONS,
      () => {
        loadPendingRequests();
        loadProcessedPrescriptions();
      },
    );

    return function cleanup() {
      EventRegister.removeEventListener(listener);
    };
  });

  const renderSpinner = () => {
    return (
      <View style={{flex: 1, width: width}}>
        <ActivityIndicator style={CommonStyles.activityIndicator} />
      </View>
    );
  };

  return (
    <Container style={styles.container}>
      <ScrollableTab
        titles={['Nuevas', 'Procesadas']}
        renderHeader={(index: number) => {
          return (
            <View style={Theme.headerComponent}>
              <ButtonIconHeader marginLeft={24}  />
            </View>
          );
        }}>
        {pendingRequests === null ? (
          renderSpinner()
        ) : (
          <PrescriptionsList
            prescriptionRequestId={prescriptionRequestId}
            prescriptions={pendingRequests}
            emptyText={'No tienes solicitudes de recetas recientes'}
            title="Solicitudes nuevas"
            type={ConsultsType.PrescriptionRequest}
            stylesItem={{width: '97%'}}
            refreshing={refreshingPending}
            onLoadMore={onLoadMorePendingPrescriptions}
            total={totalPending}
          />
        )}
        {processedPrescriptions === null ? (
          renderSpinner()
        ) : (
          <PrescriptionsList
            prescriptions={processedPrescriptions}
            emptyText={'No tienes solicitudes de recetas procesadas'}
            title={'Solicitudes procesadas'}
            type={ConsultsType.PrescriptionRequest}
            stylesItem={{width: '97%'}}
            refreshing={refreshingProcessed}
            onLoadMore={onLoadMoreProcessedPrescriptions}
            total={totalProcessed}
          />
        )}
      </ScrollableTab>
    </Container>
  );
};

export default PrescriptionsRequests;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  filterView: {
    position: 'absolute',
    alignSelf: 'center',
    bottom: getBottomSpace() + 16,
    // right: 24,
    backgroundColor: Colors.BlueCrayola,
    // width: 200,
    height: 56,
    borderRadius: 16,
  },
});
