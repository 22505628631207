import React, { useState, useEffect } from "react";
import { Colors, Routes } from "../../../configs";
import { useHistory } from "react-router-dom";
import {
  EVENT_RELOAD_CERTIFICATES,
  EVENT_SHOW_MODAL_INFO,
  EVENT_RELOAD_PATIENT,
  EVENT_SHOW_MODAL_ACTION,
} from "../../../constants/Events";
import locales_es from "../../../locales/es";
import {
  validateEmptyArrays,
  validateEmptyFields,
} from "../../../utils/validation/forms";
import { EventRegister } from "../../../modules/EventRegister";
import {
  showModalInfo,
  showModalWithInputCuit,
  showModalAction,
} from "../../../modules/ViewHelpers";
import { api, dateTimeService, helpers } from "../../../import";
import { useTheme } from "../../../configs/Theme";
import CoachMarksService from "../../../modules/coachmarks";
import { ConsultsType } from "../../../configs/Const";
import {
  INSURANCE_PERMISSION,
  PRACTICES_PERMISSION,
  PRESCRIPTION_STYLE_FREE,
  PRESCRIPTION_STYLE_PRACTICES,
} from "../../../models/constants";
import LocalAuthService from "../../../modules/LocalAuthService";
import {
  setInStorage,
  AsyncStorageEnum,
  getFromStorage,
} from "../../../utils/storage";
import CountryService from "../../../modules/CountryService";
import validateCuitByCountry from "../../../utils/validation/cuit";

const useAddCertificatePage = () => {
  const { theme }: any = useTheme();
  const history: any = useHistory();
  const params: any = history.location.state;
  let goBackAlreadyDismissed = false;

  const coachMarksService = new CoachMarksService();
  const localAuthService = new LocalAuthService();
  const [coachmark, setCoachmark] = useState(false);

  const [prescriptionId] = useState(params ? params.prescriptionId : null);

  const [patientId, setPatientId] = useState(
    params ? params.selectPatientId : null
  );

  const [overlay, setOverlay] = useState<any>(false);
  const [errors, setErrors] = useState([]);
  const [practices, setPractices] = useState<any>([]); // [nombre]
  const [newPractices, setNewPractices] = useState([]); // [nombre]
  const [comboSelected, setComboSelected] = useState<any>([]); // [id]
  const [congrats, setCongrats] = useState(false);
  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [alreadySent, setAlreadySent] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [prescriptionProfileId, setPrescriptionProfileId] = useState(null);
  const [prescriptionProfiles, setPrescriptionProfiles] = useState(null);
  const [prescriptionStyle, setPrescriptionStyle] = useState("");
  const [userData, setUserData] = useState(null);
  const [showLicenseModal, setShowLicenseModal] = useState(false);
  const [medicalLicenseSelected, setMedicalLicenseSelected] = useState(null);

  const [diagnostic, setDiagnostic] = useState(""); // [id]
  const [diagnosticCod, setDiagnosticCod] = useState("");
  const [text, setText] = useState("");
  const [indications, setIndications] = useState("");
  const [initDiagnostic, setInitDiagnostic] = useState("");
  const initialDate = new Date();
  const minDate = new Date();
  // minDate.setMonth(minDate.getMonth() - 1);
  const [dates, setDates] = useState([initialDate]);
  const [enabledSelectionType, setEnabledSelectionType] = useState(true);
  const [medicalInsurances, setMedicalInsurances] = useState<any>([]);
  const [permissions, setPermissions] = useState(null);
  const [insurancePermissionFlag, setInsurancePermissionFlag] = useState(false);
  const [clinicId, setClinicId] = useState(null);
  const [certificateData, setCertificateData] = useState(null);

  useEffect(() => {
    if (params && params.certificateData) {
      setPractices(params.certificateData.practices);
      setNewPractices(params.certificateData.others_practices);
      setPrescriptionProfileId(params.certificateData.prescription_profile_id);
      setDiagnostic(params.certificateData.diagnostic);
      setDiagnosticCod(params.certificateData.diagnostic_cod);
      setText(params.certificateData.notes ?? params.certificateData.text);
      setIndications(params.certificateData.indications);
      setDates([initialDate]);
      setUserData(params.certificateData.userData);
      setPrescriptionStyle(params.certificateData.prescriptionStyle);
    }
    if (params && params.selectPatientId) {
      setPatientId(params.selectPatientId);
    }
  }, [params]);
  const setPrescriptionProfile = async (profileId: any) => {
    if (profileId) {
      setPrescriptionProfileId(profileId);
      setInStorage(AsyncStorageEnum.PRESCRIPTION_PROFILE_ID, `${profileId}`);
    } else {
      let getPrescriptionProfile = await getFromStorage(
        AsyncStorageEnum.PRESCRIPTION_PROFILE_ID
      );
      if (getPrescriptionProfile !== null) {
        setPrescriptionProfileId(JSON.parse(getPrescriptionProfile));
      }
    }
  };

  const loadPrescriptionProfiles = () => {
    api
      .getMyPrescriptionProfiles()
      .then(async (res: any) => {
        if (res && res.data && res.data.length) {
          if (!prescriptionId) {
            let getPrescriptionProfile = await getFromStorage(
              AsyncStorageEnum.PRESCRIPTION_PROFILE_ID
            );
            if (getPrescriptionProfile !== null) {
              setPrescriptionProfile(JSON.parse(getPrescriptionProfile));
            } else {
              await setPrescriptionProfile(res.data[0].id);
            }
          } else {
            // This GET is also responsible for loading de prescription data
            api
              .getMedicalCertificate(prescriptionId)
              .then(async (res: any) => {
                const p = res.data;
                // setPatientId(p.patient_id); // its configured passing patiendId as param
                await setPrescriptionProfile(p.prescription_profile_id);
                if (
                  p.medical_certificates_practices &&
                  p.medical_certificates_practices.length
                ) {
                  setPrescriptionStyle(PRESCRIPTION_STYLE_PRACTICES);
                  setPractices(
                    p.medical_certificates_practices.map((practice: any) => {
                      practice.medical_practice.is_censionated =
                        practice.is_censionated;
                      practice.medical_practice.quantity = practice.quantity;
                      practice.medical_practice.observations = practice.comment;
                      return practice.medical_practice;
                    })
                  );
                  setText(p.notes);
                  setIndications(p.indications);
                  setDiagnostic(p.diagnostic);
                  setDiagnosticCod(p.diagnostic_cod);
                  setDates([new Date()]);
                  if (
                    p.medical_certificates_others_practices &&
                    p.medical_certificates_others_practices.length
                  ) {
                    setNewPractices(
                      p.medical_certificates_others_practices.map(
                        (practice: any) => {
                          practice.others_medicals_practices.is_censionated =
                            practice.is_censionated;
                          practice.others_medicals_practices.quantity =
                            practice.quantity;
                          practice.others_medicals_practices.observations =
                            practice.comment;
                          return practice.others_medicals_practices;
                        }
                      )
                    );
                  }
                } else {
                  setPrescriptionStyle(PRESCRIPTION_STYLE_FREE);
                  setText(p.text);
                  setIndications(p.indications);
                  setDiagnostic(p.diagnostic);
                  setDiagnosticCod(p.diagnostic_cod);
                  setDates([new Date()]);
                }
              })
              .catch((err: any) => {
                showModalInfo(
                  locales_es.errorModal.title,
                  helpers.getErrorMsg(err)
                );
              });
          }
          setPrescriptionProfiles(res.data);
        } else {
          showModalInfo(
            locales_es.errorModal.title,
            "No hay un perfil de receta disponible. Debe crear al menos uno para generar recetas"
          );
        }
      })
      .catch((err: any) => {
        helpers.processError(err);
      });
  };

  const onPressSearchResult = (item: any, index: any) => {
    if (item && index == -1) {
      setDiagnostic(`${item.coddiagnostico} - ${item.descdiagnostico}`);
      setDiagnosticCod(item.coddiagnostico);
    } else {
      setInitDiagnostic(`${item.coddiagnostico} - ${item.descdiagnostico}`);
    }
  };

  const setProfileByPatientId = (
    patientId: any,
    clinicId: any,
    prescriptionProfiles = null
  ) => {
    if (patientId && prescriptionProfiles != null) {
      prescriptionProfiles.map((profile) => {
        if (profile.clinic_id !== null && profile.clinic_id === clinicId) {
          setPrescriptionProfile(profile.id);
          console.log("--3.3");
        }
      });
    }
  };

  useEffect(() => {
    if (patientId && prescriptionProfiles && clinicId) {
      setProfileByPatientId(patientId, clinicId, prescriptionProfiles);
    }
  }, [patientId, prescriptionProfiles, clinicId]);

  useEffect(() => {
    // Check if practices are enabled (enabled_certificates)
    const countryService = new CountryService();
    countryService
      .getData()
      .then((res: any) => {
        // alert(JSON.stringify(res));
        api
          .getCountry(res.id)
          .then((res2: any) => {
            if (res2 && res2.data) {
              if (!res2.data.enabled_certificates) {
                setPrescriptionStyle(PRESCRIPTION_STYLE_FREE);
              }
              setEnabledSelectionType(res2.data.enabled_certificates);
            }

            setLoad(false);
          })
          .catch((err2: any) => {
            console.log(err2);
            setLoad(false);
          });
      })
      .catch((err: any) => {
        console.log(err);
        setLoad(false);
      });
  }, []);

  const loadPermissions = () => {
    return new Promise((resolve) => {
      api
        .getPermissionsMe()
        .then((res: any) => {
          setPermissions(res);
          resolve(res);
        })
        .catch((err: any) => {
          helpers.processError(err);
        });
    });
  };

  const confirmPermissions = () => {
    history.goBack();
  };

  useEffect(() => {
    loadPermissions().then((res: any) => {
      if (res && res.some((perm: any) => perm.tag === PRACTICES_PERMISSION)) {
        if (params && params.patientId) {
          setPatientId(params.patientId);
          api
            .getPatient(params.patientId)
            .then((res: any) => {
              setUserData(res.data);
              if (res.data.clinic_id != null) {
                setClinicId(res.data.clinic_id);
              }
            })
            .catch((err: any) => {
              helpers.processError(err);
            });

          api
            .getPatientMedicalInsurances(params.patientId)
            .then((resInsus: any) => {
              if (resInsus.data.length > 0) {
                let data = resInsus.data.map((item: any) => {
                  return {
                    patients_medical_insurances_id: item.id,
                    medical_insurance_id: item.medical_insurance_id,
                    medical_insurance_name: item.medical_insurance.name,
                    medical_insurance_plan: item.medical_insurance_plan,
                    medical_insurance_number: item.medical_insurance_number,
                    medical_insurance_selected: item.medical_insurance_selected,
                    tieneCobertura: item.medical_insurance.tieneCobertura
                      ? item.medical_insurance.tieneCobertura
                      : 0,
                    idFinanciador: item.medical_insurance.idFinanciador
                      ? item.medical_insurance.idFinanciador
                      : 0,
                  };
                });

                data = data.sort((a: any, b: any) => {
                  if (a.medical_insurance_id === 1) return 1;
                  if (b.medical_insurance_id === 1) return -1;
                  return 0;
                });

                if (
                  res &&
                  res.some((perm: any) => perm.tag === INSURANCE_PERMISSION)
                ) {
                  setInsurancePermissionFlag(true);
                } else {
                  setInsurancePermissionFlag(false);
                  let indexSelected = -1;
                  let idInsuranceSelected = -1;
                  data = data.map((insurance: any, index: number) => {
                    insurance.medical_insurance_selected =
                      insurance.medical_insurance_name === "Sin cobertura"
                        ? 1
                        : 0;
                    indexSelected = index;
                    idInsuranceSelected = insurance.medical_insurance_id;
                    return insurance;
                  });
                  if (indexSelected != -1 && idInsuranceSelected != -1) {
                    onMedicalInsuranceSelect(
                      indexSelected,
                      idInsuranceSelected
                    );
                  }
                }

                setMedicalInsurances(data);
              } else {
                const newItem = [
                  {
                    id: 0,
                    medical_insurance_id: 1,
                    medical_insurance_plan: "",
                    medical_insurance_number: "",
                    medical_insurance_name: "Sin cobertura",
                  },
                ];
                setMedicalInsurances(newItem);
              }
            })
            .catch((err: any) => {
              helpers.processError(err);
            });
        }

        loadPrescriptionProfiles();

        coachMarksService
          .checkIfHasToShow(Routes.AddPrescription) // AddPrescription, yes.
          .then((res: any) => {
            if (res !== true) {
              setCoachmark(true);
            }
            coachMarksService.setShownScreen(Routes.AddPrescription);
          })
          .catch((err: any) => {
            console.log(err);
          });

        return;
      }
      EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
        title: locales_es.modalPermission.title,
        message: locales_es.modalPermission.description,
        actions: [
          { text: "Aceptar", onPress: confirmPermissions, white: true },
        ],
      });
    });
  }, []);

  useEffect(() => {
    const listener = EventRegister.addEventListener(
      EVENT_RELOAD_PATIENT,
      (data) => {
        api
          .getPatientMedicalInsurances(data)
          .then((res: any) => {
            if (res.data.length > 0) {
              let data = res.data.map((item: any) => {
                return {
                  patients_medical_insurances_id: item.id,
                  medical_insurance_id: item.medical_insurance_id,
                  medical_insurance_name: item.medical_insurance.name,
                  medical_insurance_plan: item.medical_insurance_plan,
                  medical_insurance_number: item.medical_insurance_number,
                  medical_insurance_selected: item.medical_insurance_selected,
                };
              });

              data = data.sort((a, b) => {
                if (a.medical_insurance_id === 1) return 1;
                if (b.medical_insurance_id === 1) return -1;
                return 0;
              });

              setInsurancePermissionFlag(
                permissions?.some(
                  (perm: any) => perm.tag === INSURANCE_PERMISSION
                )
              );

              setMedicalInsurances(data);
            } else {
              const newItem = [
                {
                  id: 0,
                  medical_insurance_id: 1,
                  medical_insurance_plan: "",
                  medical_insurance_number: "",
                  medical_insurance_name: "Sin cobertura",
                },
              ];
              setMedicalInsurances(newItem);
            }
          })
          .catch((err: any) => {
            helpers.processError(err);
          });
      }
    );

    return function cleanup() {
      EventRegister.removeEventListener(listener);
    };
  });

  useEffect(() => {
    const unblock = history.block((newLocation, action) => {
      if (action === "POP") {
        if (
          (text || patientId || practices.length) &&
          !alreadySent &&
          !goBackAlreadyDismissed
        ) {
          EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
            title: locales_es.confirmBackAlert.title,
            message: locales_es.confirmBackAlert.subtitle,
            actions: [
              {
                text: locales_es.confirmBackAlert.cancel,
                onPress: () => console.log("Cancel Pressed"),
                white: true,
              },
              {
                text: locales_es.confirmBackAlert.accept,
                onPress: () => {
                  // history.push(newLocation);
                  goBackAlreadyDismissed = true;
                  history.goBack();
                },
                white: true,
                colors: [Colors.Tomato, Colors.Red],
              },
            ],
          });
          return false;
        }
        return true;
      } else {
        // If a "POP" action event occurs,
        // Send user back to the originating location
        // history.go(1);
        return true;
      }
    });

    return () => {
      unblock();
    };
  }, [text, patientId, alreadySent]);

  const onBlur = (inputStr: string) => {
    setErrors(errors.filter((item) => item !== inputStr));
  };

  const sendData = () => {
    const errors = validateEmptyFields({
      patientId,
    });

    if (
      prescriptionStyle === PRESCRIPTION_STYLE_PRACTICES &&
      !validateEmptyArrays(practices) &&
      !validateEmptyArrays(newPractices)
    ) {
      errors.push("practices");
    }

    if (
      prescriptionStyle === PRESCRIPTION_STYLE_FREE &&
      validateEmptyFields({ text }).length
    ) {
      errors.push("text");
    }
    if (errors.length) {
      setErrors(errors);
      showModalInfo(locales_es.infoModal.title, locales_es.infoModal.checkData);
      // return;
    } else {
      localAuthService
        .getConfig()
        .then((res: any) => {
          confirmSend();
        })
        .catch((err: any) => {
          confirmSend();
        });
    }
  };

  const onIncompleteProfile = () => {
    console.log("onIncompleteProfile");
    console.log(certificateData);
    history.push(`/${Routes.AddMedicalLicense}`, {
      edit: true,
      medicalLicense: medicalLicenseSelected,
      from: Routes.AddCertificate,
      certificateData: certificateData,
    });
  };

  const onSend = async () => {
    const prescriptionProfileSelected = prescriptionProfiles.find(
      (profile: any) => profile.id == prescriptionProfileId
    );

    if (
      helpers.licenseValidator(prescriptionProfileSelected.medical_licenses)
    ) {
      setShowLicenseModal(true);
      setMedicalLicenseSelected(prescriptionProfileSelected.medical_licenses);
      const objData: any = {
        prescriptionStyle: prescriptionStyle,
        userData: userData,
        dates: [initialDate],
        prescription_profile_id: prescriptionProfileId,
        selectPatientId: patientId,
        // date: dateTimeService.parseDateToAPIString(new Date()),
        date: dateTimeService.parseDateToAPIString(new Date(dates[0])),
      };

      if (prescriptionStyle === PRESCRIPTION_STYLE_FREE) {
        objData.text = text;
      } else if (prescriptionStyle === PRESCRIPTION_STYLE_PRACTICES) {
        objData.practices = practices.map((p: any) => {
          return {
            name: p.name,
            id: p.id,
            quantity: p.quantity,
            observations: p.observations,
            is_censionated: p.is_censionated,
          };
        });
        objData.notes = text;
        objData.others_practices = newPractices.length
          ? newPractices.map((p: any) => {
              return {
                name: p.name,
                quantity: p.quantity,
                observations: p.observations,
                is_censionated: p.is_censionated,
              };
            })
          : [];
      }

      if (indications !== "") {
        objData.indications = indications;
      }

      if (diagnostic !== "") {
        objData.diagnostic = diagnostic;
        if (diagnosticCod != "") {
          objData.diagnostic_cod = diagnosticCod;
        }
      }
      setCertificateData(objData);
      return;
    }
    // validador para cuit obligatopiro por pais
    const { validateCuit, requestCuit, requestIdTributary, taxId } =
      await validateCuitByCountry();

    let isOsde = false;
    for (let i = 0; i < medicalInsurances.length; i++) {
      if (
        medicalInsurances[i].medical_insurance_selected == 1 &&
        medicalInsurances[i].medical_insurance_id == 206
      ) {
        isOsde = true;
      }
    }

    if (isOsde && requestIdTributary && !taxId) {
      if (requestCuit) {
        // cuit obligatorio
        showModalWithInputCuit(
          locales_es.requiredCuitModal.title,
          sendCuit,
          null,
          "00-0000000-0",
          locales_es.requiredCuitModal.subtitle,
          "50%"
        );
      } else {
        // de lo contrario el cancelar tambien enviara el cuit
        showModalWithInputCuit(
          locales_es.requiredCuitModal.title,
          sendCuit,
          sendData,
          "00-0000000-0",
          locales_es.requiredCuitModal.subtitle,
          "50%"
        );
      }
    } else {
      sendData();
    }
  };

  const sendCuit = (cuit: string = "") => {
    const _userData: any = localStorage.getItem("user_data");
    let user: any = JSON.parse(_userData).user;
    if (user) {
      api
        .updateCuit({
          id: user.id,
          idTributario: cuit,
        })
        .then((res: any) => {
          sendData();
        })
        .catch((err: any) => {
          showModalInfo(locales_es.errorModal.title, helpers.getErrorMsg(err));
        });
    } else {
      showModalInfo(locales_es.errorModal.title, locales_es.errorFormatCuit);
    }
  };

  const maxLengthInput = (value: string) => {
    if (value.length >= 250) {
      setErrors([...errors, "diagnostic"]);
    } else {
      setErrors(errors.filter((item) => item !== "diagnostic"));
    }
  };

  const confirmSend = () => {
    const datesToSend: any = [];

    for (const date of dates) {
      datesToSend.push(dateTimeService.parseDateToAPIString(new Date(date)));
    }

    const objData: any = {
      dates: datesToSend,
      prescription_profile_id: prescriptionProfileId,
      patient_id: patientId,
      // date: dateTimeService.parseDateToAPIString(new Date()),
      date: dateTimeService.parseDateToAPIString(new Date(dates[0])),
    };

    if (prescriptionStyle === PRESCRIPTION_STYLE_FREE) {
      objData.text = text;
    } else if (prescriptionStyle === PRESCRIPTION_STYLE_PRACTICES) {
      objData.practices = practices.map((p: any) => {
        return { id: p.id, quantity: p.quantity, observations: p.observations };
      });
      objData.notes = text;
      objData.others_practices = newPractices.length
        ? newPractices.map((p: any) => {
            return {
              name: p.name,
              quantity: p.quantity,
              observations: p.observations,
            };
          })
        : [];
    }

    if (indications !== "") {
      objData.indications = indications;
    }

    if (diagnostic !== "") {
      objData.diagnostic = diagnostic;
      if (diagnosticCod != "") {
        objData.diagnostic_cod = diagnosticCod;
      }
    }

    setLoading(true);
    setLoadingText(locales_es.creatingCertificate);
    api
      .postCertificate(objData)
      .then((res: any) => {
        setAlreadySent(true);
        setLoading(false);
        setLoadingText("");
        setCongrats(true);
        setTimeout(() => {
          history.replace(`/${Routes.DocumentVisor}`, {
            documents: [
              {
                pdfSource: { uri: res.data.full_file_url },
                prescriptionId: res.data.id,
              },
            ],
            screenTitle: locales_es.createdCertificate,
            selectionType: ConsultsType.Certificate,
            emails:
              res.data.patient && res.data.patient.email
                ? [{ value: res.data.patient.email }]
                : null,
            overlay: true,
            patientId,
          });
          setCongrats(false);
          EventRegister.emit(EVENT_RELOAD_CERTIFICATES);
        }, 3000);
      })
      .catch((err: any) => {
        setLoading(false);
        setLoadingText("");
        helpers.processError(err);
      });
  };

  const onPressActivate = () => {
    showModalInfo(
      locales_es.successModal.title,
      locales_es.prescriptionsActivatedNow
    );
    localAuthService.setConfig(true);
    setCoachmark(false);
  };

  const onPressLater = () => {
    showModalInfo(
      locales_es.infoModal.title,
      locales_es.prescriptionsActivatedLater
    );
    setCoachmark(false);
  };

  const setDateArray = (date: any, index: number) => {
    const _dates = [...dates];
    _dates[index] = new Date(date);
    setDates(_dates);
  };

  const onPrescriptionProfileSelect = (profileId: any) => {
    if (practices && practices.length) {
      EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
        title: locales_es.confirmOnPrescriptionProfileSelect.title,
        message: locales_es.confirmOnPrescriptionProfileSelect.subtitle,
        actions: [
          {
            text: locales_es.confirmOnPrescriptionProfileSelect.cancel,
            onPress: () => console.log("Cancel Pressed"),
            white: true,
          },
          {
            text: locales_es.confirmOnPrescriptionProfileSelect.accept,
            onPress: () => {
              setPrescriptionProfile(profileId);
              setPractices([]);
              setNewPractices([]);
              setComboSelected([]);
              setText("");
              setIndications("");
              setDiagnostic("");
              setDiagnosticCod("");
              setInitDiagnostic("");
            },
            white: true,
            colors: [Colors.Tomato, Colors.Red],
          },
        ],
      });
      return;
    }
    setPrescriptionProfile(profileId);
  };

  // useEffect(() => {
  //   console.log("prescriptionProfileId", prescriptionProfiles);
  // }, [prescriptionProfiles]);

  const setQuantityNewPractice = (index: any, num: number) => {
    const _newPractices = [...newPractices];
    _newPractices[index].quantity = num;
    setNewPractices(_newPractices);
  };

  const setQuantity = (index: any, num: number) => {
    const _practices = [...practices];
    _practices[index].quantity = num;
    setPractices(_practices);
  };

  const onSetObservationsPractices = (index: any, value: any) => {
    const items = JSON.parse(JSON.stringify(practices));
    items[index].observations = value;
    setPractices(items);
  };

  const onSetObservationsNewPractices = (index: any, value: any) => {
    const items = JSON.parse(JSON.stringify(newPractices));
    items[index].observations = value;
    setNewPractices(items);
  };

  const onPressPracticeX = (index: any) => {
    const items = JSON.parse(JSON.stringify(practices));
    items.splice(index, 1);
    setPractices(items);
  };

  const onPressCensionated = (index: any) => {
    const _practices = [...practices];
    _practices[index].is_censionated = !_practices[index].is_censionated;

    if (!_practices[index].is_censionated) {
      _practices[index].quantity = 1;
    }

    setPractices(_practices);
  };

  const clearPrescriptionType = () => {
    if (text || practices.length) {
      EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
        title: "¿Desea cambiar el tipo de prescripción?",
        message: "Tiene texto o prácticas añadidas que se perderán si continúa",
        actions: [
          {
            text: locales_es.cancel,
            onPress: () => console.log("Cancel Pressed"),
            white: true,
          },
          {
            text: locales_es.change,
            onPress: () => {
              confirmClearPrescriptionType();
            },
            white: true,
            colors: [Colors.Tomato, Colors.Red],
          },
        ],
      });
      return;
    }
    confirmClearPrescriptionType();
  };

  const confirmClearPrescriptionType = () => {
    setText("");
    setIndications("");
    setPrescriptionStyle("");
    setErrors([]);
    setPractices([]);
    setNewPractices([]);
    setComboSelected([]);
    setDiagnostic("");
    setInitDiagnostic("");
    setDiagnosticCod("");
  };

  const onMedicalInsuranceSelect = (index: any, idSelected: number = -1) => {
    const medicalInsuranceSelected = medicalInsurances.map((item, ind) => {
      if (
        ind === index ||
        (idSelected !== -1 && idSelected == item.medical_insurance_id)
      ) {
        item.medical_insurance_selected = 1;
      } else {
        item.medical_insurance_selected = 0;
      }
      return item;
    });

    if (idSelected == -1) {
      setMedicalInsurances(medicalInsuranceSelected);
    }

    const objectData = {
      medicals_insurances: medicalInsuranceSelected,
    };

    api
      .updateMedicalsInsurancesSelected(patientId, objectData)
      .then((res: any) => {
        {
          console.log(res);
        }
      })
      .catch((error: any) => {
        {
          console.log(error);
        }
      });
  };

  const onPressNewPracticeX = (index: any) => {
    const items = JSON.parse(JSON.stringify(newPractices));
    items.splice(index, 1);
    setNewPractices(items);
  };

  const onPressCensionatedNewPractice = (index: any) => {
    const _newPractices = [...newPractices];
    _newPractices[index].is_censionated = !_newPractices[index].is_censionated;

    if (!_newPractices[index].is_censionated) {
      _newPractices[index].quantity = 1;
    }
    setNewPractices(_newPractices);
  };

  return {
    medicalLicenseSelected,
    onIncompleteProfile,
    showLicenseModal,

    onPressSearchResult,
    confirmClearPrescriptionType,
    setAlreadySent,
    setCoachmark,
    setCongrats,
    setDateArray,
    setDates,
    setEnabledSelectionType,
    setErrors,
    setInStorage,
    setIndications,
    setLoad,
    setLoading,
    setLoadingText,
    setOverlay,
    setPatientId,
    setPractices,
    setPrescriptionProfile,
    setPrescriptionProfileId,
    setPrescriptionProfiles,
    setPrescriptionStyle,
    setDiagnostic,
    setText,
    setUserData,
    setNewPractices,
    setComboSelected,
    setInitDiagnostic,
    clearPrescriptionType,
    onPressPracticeX,
    onPrescriptionProfileSelect,
    onPressLater,
    onPressActivate,
    confirmSend,
    onBlur,
    onSend,
    onMedicalInsuranceSelect,
    onPressNewPracticeX,
    maxLengthInput,
    enabledSelectionType,
    userData,
    prescriptionProfiles,
    loadingText,
    load,
    loading,
    overlay,
    congrats,
    practices,
    coachmark,
    theme,
    prescriptionProfileId,
    errors,
    dates,
    minDate,
    patientId,
    prescriptionStyle,
    indications,
    diagnostic,
    text,
    medicalInsurances,
    newPractices,
    comboSelected,
    diagnosticCod,
    initDiagnostic,
    setDiagnosticCod,
    onPressCensionated,
    onPressCensionatedNewPractice,
    setQuantityNewPractice,
    setQuantity,
    permissions,
    insurancePermissionFlag,
    onSetObservationsPractices,
    onSetObservationsNewPractices,
  };
};

export default useAddCertificatePage;
