import React, { memo, useCallback, useEffect } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  ActivityIndicator,
  ScrollView,
  Modal,
} from "react-native";
import Text from "../../../components/Text";
import Theme from "../../../style/Theme";
import scale from "../../../utils/scale";
import Layout from "../../../components/Layout/Layout";
import { getStatusBarHeight } from "react-native-iphone-x-helper";
import SelectPatient from "../../../components/Woopi/SelectPatient";
import ButtonLinear from "../../../components/Buttons/ButtonLinear";
import Colors from "../../../configs/Colors";
import Routes from "../../../configs/Routes";
import TextInput from "../../../components/TextInput";
import locales_es from "../../../locales/es";
import FullScreenLoading from "../../../components/fullscreenLoading";
import FullScreenGreenCheck from "../../../components/FullscreenGreenCheck";
import { ConsultsType } from "../../../configs/Const";
import PrescriptionProfiles from "../../../components/WorkProfileManagement/PrescriptionProfiles";
import {
  PRESCRIPTION_STYLE_FREE,
  PRESCRIPTION_STYLE_PRACTICES,
} from "../../../models/constants";
import ButtonText from "../../../components/Buttons/ButtonText";
import { FEATURE_ICON } from "../../../images/MyWork";
import FeatureItem from "../../../components/FeatureItem";
import TagItem from "../../../components/WorkProfile/TagItem";

import FormNewDatePicker from "../../../components/formNewDatePicker";
import Overlay from "../../../components/Overlay";
import { MyPatientsDesktop, MyPatientsMobile } from "../../MyPatients";
import iconFeedActive from "../../../images/ic_feed_active.png";
import iconArrowRight from "../../../images/ic_arrow_right.png";
import SearchPractices from "../../SearchPractices";
import useAddCertificatePage from "../shared/useAddCertificatePage";
import avatarProfileIcon from "../../../images/avatar/ic_account_normal1.png";
import Card from "../../../components/Card";
import { SOURCE_ICON } from "../../../images";
import { EventRegister } from "../../../modules/EventRegister";
import {
  EVENT_SHOW_MODAL_INFO,
  EVENT_RELOAD_PATIENT,
} from "../../../constants/Events";
import PatientMedicalInsurancesList from "../../../components/PatientMedicalInsurances";
import DiagnosticAutocompleteItem from "../../../components/DiagnosticAutocomplete";
import InputSpinner from "react-native-input-spinner";
import Line from "../../../components/Layout/Line";
import AccountItem from "../../../components/AccountItem/Index";
import { set } from "react-native-reanimated";

interface AddCertificateProps {}

const AddCertificate = memo((props: AddCertificateProps) => {
  const {
    overlay,
    minDate,
    congrats,
    loading,
    onPressPracticeX,
    onPressNewPracticeX,
    indications,
    onBlur,
    setDiagnostic,
    diagnostic,
    setText,
    text,
    setIndications,
    onSend,
    setOverlay,
    setPatientId,
    setPrescriptionStyle,
    practices,
    setPractices,
    setUserData,
    patientId,
    theme,
    prescriptionStyle,
    enabledSelectionType,
    clearPrescriptionType,
    loadingText,
    prescriptionProfiles,
    userData,
    onPrescriptionProfileSelect,
    prescriptionProfileId,
    errors,
    setDateArray,
    dates,
    medicalInsurances,
    onMedicalInsuranceSelect,
    newPractices,
    setNewPractices,
    comboSelected,
    setComboSelected,
    maxLengthInput,
    diagnosticCod,
    setDiagnosticCod,
    onPressCensionated,
    onPressCensionatedNewPractice,
    setQuantityNewPractice,
    setQuantity,
    onSetObservationsPractices,
    onSetObservationsNewPractices,
    permissions,
    insurancePermissionFlag,
    initDiagnostic,
    setInitDiagnostic,
    onPressSearchResult,
    onIncompleteProfile,
    showLicenseModal,
    medicalLicenseSelected,
  } = useAddCertificatePage();

  useEffect(() => {
    if (practices && practices.length === 0) {
      setComboSelected([]);
    }
  }, [practices]);

  const onFocus = useCallback(() => {
    setOverlay(
      <View style={{ position: "relative" }}>
        <SearchPractices
          showMainModal={(
            title,
            content,
            actions,
            customStyles,
            contentCustomClassName
          ) =>
            props.showMainModal(
              title,
              content,
              actions,
              customStyles,
              contentCustomClassName
            )
          }
          hideMainLoading={props.hideMainLoading}
          onComplete={(
            _practices: any,
            combosSelected: any,
            _indications: any,
            _diagnostic: any
          ) => {
            if (_diagnostic !== null && _diagnostic !== "") {
              setDiagnostic(_diagnostic);

              setInitDiagnostic(_diagnostic);
            }
            if (
              _indications !== "" &&
              _indications !== null &&
              _indications !== undefined
            ) {
              setIndications((prevIndications) => {
                if (prevIndications.length > 0) {
                  if (prevIndications.length + _indications.length > 250) {
                    return prevIndications;
                  }

                  return prevIndications + ", " + _indications + ".";
                } else {
                  return _indications;
                }
              });
            }
            if (practices.length > 0) {
              //aqui verificamos que _practices contenga id en su array
              _practices.map((item: any) => {
                if (item.id) {
                  const filterPractive = _practices.filter((el: any) => {
                    return !practices.some((item: any) => item.id === el.id);
                  });
                  setPractices(practices.concat(filterPractive));
                }
              });
            } else {
              let practs: any = [];
              _practices.map((item: any) => {
                if (item.id) {
                  practs.push(item);
                }
              });
              setPractices(practs);
            }

            if (newPractices.length > 0) {
              //aqui verificamos que _practices no contenga id en su array
              _practices.map((item: any) => {
                if (!item.id) {
                  const filterPractive = _practices.filter((el: any) => {
                    return !newPractices.some(
                      (item: any) => item.name === el.name
                    );
                  });
                  setNewPractices(newPractices.concat(filterPractive));
                }
              });
            } else {
              let _practs: any = [];
              _practices.map((item: any) => {
                if (item.id === undefined) {
                  _practs.push(item);
                }
              });
              setNewPractices(_practs);
            }

            if (comboSelected.length > 0) {
              const comSel = combosSelected.filter(
                (item: any) => item !== null
              );

              if (comSel.length > 0) {
                comSel.map((item: any) => {
                  const filterCombo = comSel.filter((el: any) => {
                    return !comboSelected.some(
                      (item: any) => item === el && item !== null
                    );
                  });
                  setComboSelected(comboSelected.concat(filterCombo));
                });
              }
            } else {
              //aqui insertamos los id de los combos seleccionados
              let combos: any = [];
              combosSelected.map((item: any) => {
                if (item !== null) {
                  combos.push(item);
                }
              });

              setComboSelected(combos);
            }

            setOverlay(null);
          }}
          prescriptionProfileId={prescriptionProfileId}
          selectedPractices={practices}
          comboSelected={comboSelected}
          countPractices={practices.length + newPractices.length}
          onGoBack={() => setOverlay(null)}
        />
      </View>
    );
  }, [
    practices,
    prescriptionProfileId,
    newPractices,
    comboSelected,
    indications,
    diagnostic,
    initDiagnostic,
  ]);

  const selectPatient = () => {
    setOverlay(
      <View>
        <MyPatientsDesktop
          showMainModal={(
            title,
            content,
            actions,
            customStyles,
            contentCustomClassName
          ) =>
            props.showMainModal(
              title,
              content,
              actions,
              customStyles,
              contentCustomClassName
            )
          }
          hideMainLoading={props.hideMainLoading}
          selectPatientId={true}
          selectionType={ConsultsType.Certificate}
          onSelectPatient={(patient) => {
            setPatientId(patient.id);
            setUserData(patient);
            setOverlay(null);
            EventRegister.emit(EVENT_RELOAD_PATIENT, patient.id);
          }}
          source="certificate"
          onGoBack={() => setOverlay(null)}
        />
      </View>
    );
  };

  const renderFreePrescription = (label: string, placeholder: string) => {
    return (
      <Card header={{ icon: SOURCE_ICON.doctor, title: label }}>
        <View>
          <View
            style={{
              ...Theme.flexRow,
            }}
          >
            <TextInput
              placeholderTextColor={theme.placeholder}
              placeholder={placeholder}
              value={text}
              style={{
                fontFamily: "Muli_400Regular",
                fontSize: 15,
                lineHeight: 18,
                color: theme.text,
                height: 180,
                flex: 1,
                borderWidth: 1,
                borderColor: errors.includes("text")
                  ? theme.lineErrorColor
                  : theme.innearColor,
              }}
              onChangeText={setText}
              multiline={true}
              editable={true}
              onBlur={() => onBlur("text")}
            />
          </View>
        </View>
      </Card>
    );
  };

  const renderIndications = (label: string, placeholder: string) => {
    return (
      <Card header={{ icon: SOURCE_ICON.pencil, title: label }}>
        <View>
          <View
            style={{
              ...Theme.flexRow,
            }}
          >
            <TextInput
              placeholderTextColor={theme.placeholder}
              maxLength={250}
              placeholder={placeholder}
              value={indications}
              onChangeText={setIndications}
              onBlur={() => onBlur("indications")}
              style={{
                fontFamily: "Muli_400Regular",
                fontSize: 15,
                lineHeight: 18,
                color: theme.text,
                height: 180,
                flex: 1,
                borderWidth: 1,
                borderColor: errors.includes("indications")
                  ? theme.lineErrorColor
                  : theme.innearColor,
              }}
              multiline={true}
              editable={true}
            />
          </View>
        </View>
      </Card>
    );
  };

  const renderDiagnostic = (label: string, placeholder: string) => {
    return (
      <Card
        header={{
          title: locales_es.diagnosis,
          icon: SOURCE_ICON.condition,
        }}
        style={{ zIndex: 10 }}
      >
        <View style={{ height: 200 }}>
          <DiagnosticAutocompleteItem
            diagnosticIn={diagnostic}
            diagnosticCodIn={diagnosticCod}
            errorsIn={errors}
            buttonIconFlag={true}
            tagIn={"certificate-medicine"}
            paddingRightFlagIn={true}
            onPress={(item) => onPressSearchResult(item, -1)}
            onChange={(text) => {
              setDiagnostic(text);
              setInitDiagnostic("");
            }}
            initialState={initDiagnostic}
          />
        </View>
      </Card>
    );
  };

  const renderPracticesPrescription = useCallback(() => {
    return (
      <>
        <Layout
          style={[
            styles.containerBtnPractices,
            {
              borderColor: theme.lineColor,
              backgroundColor: props.dark
                ? theme.userInfo
                : theme.backgroundItem,
            },
          ]}
        >
          <TouchableOpacity
            onPress={onFocus}
            style={[Theme.flexRow, styles.containerBtnPracticesContent]}
          >
            <Image
              source={iconFeedActive}
              style={{ width: 32, height: 32, marginRight: 20 }}
            />
            <View style={{ flexDirection: "column" }}>
              <Text size={15} lineHeight={18} bold>
                {practices.length > 0 || newPractices.length > 0
                  ? locales_es.add_more_practices
                  : locales_es.add_practices}
              </Text>
              {practices.length > 0 && newPractices.length > 0 && (
                <Text left size={13} lineHeight={20} color={"#36c6c8"}>
                  {`${
                    practices.length + newPractices.length
                  } Prácticas añadidas`}
                </Text>
              )}
              {practices.length > 0 && newPractices.length === 0 && (
                <Text left size={13} lineHeight={20} color={"#36c6c8"}>
                  {`${practices.length} Práctica${
                    practices.length > 1 ? "s" : ""
                  } añadida${practices.length > 1 ? "s" : ""}`}
                </Text>
              )}

              {newPractices.length > 0 && practices.length === 0 && (
                <Text left size={13} lineHeight={20} color={"#36c6c8"}>
                  {`${newPractices.length} Práctica${
                    newPractices.length > 1 ? "s" : ""
                  } añadida${newPractices.length > 1 ? "s" : ""}`}
                </Text>
              )}
              <>
                {practices.length === 0 && newPractices.length === 0 && (
                  <Text size={12} lineHeight={18} bold color="red">
                    {locales_es.no_practices_added}
                  </Text>
                )}
              </>
            </View>
            <Image
              source={iconArrowRight}
              style={{
                width: 16,
                height: 16,
                right: 24,
                position: "absolute",
                alignSelf: "center",
              }}
            />
          </TouchableOpacity>
          {practices && practices.length ? (
            <View
              style={{
                borderBottomColor: Colors.WhiteGray,
                borderBottomWidth: 0.5,
              }}
            />
          ) : null}
          {practices && practices.length ? (
            <ScrollView style={[styles.containerBtnPracticesContent]}>
              {practices.map((pr, index) => {
                return (
                  <>
                    <TagItem
                      title={pr.name}
                      onPressX={() => onPressPracticeX(index)}
                      iconColor={{ tintColor: "white" }}
                    />

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: pr.is_censionated
                          ? "space-between"
                          : "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <View
                        style={{
                          alignSelf: "flex-start",
                          marginTop: 8,
                        }}
                      >
                        <AccountItem
                          style={styles.switch}
                          name={"Práctica sesionada"}
                          isToggle={true}
                          onPress={() => onPressCensionated(index)}
                          switchValue={pr.is_censionated}
                        />
                      </View>

                      {pr.is_censionated && (
                        <View
                          style={{
                            alignSelf: "center",

                            marginLeft: "-10%",
                            marginTop: 5,
                            transform: [{ scale: 0.5 }],
                          }}
                        >
                          <InputSpinner
                            min={1}
                            step={1}
                            colorMax={Colors.Tomato}
                            colorMin={Colors.TealBlue}
                            color={Colors.TealBlue}
                            value={Number(pr.quantity)}
                            skin="modern"
                            onChange={(num: any) => setQuantity(index, num)}
                          />
                        </View>
                      )}
                    </View>

                    <Text
                      size={16}
                      lineHeight={24}
                      style={{
                        marginLeft: 24,
                        marginBottom: 10,
                        marginTop: 10,
                      }}
                    >
                      {locales_es.observations}
                    </Text>

                    <View
                      style={{
                        height: 100,
                        width: "90%",
                        alignSelf: "center",
                        marginBottom: 20,
                      }}
                    >
                      <TextInput
                        placeholderTextColor={theme.placeholder}
                        value={pr.observations}
                        placeholder={
                          "Añada aquí las observaciones para el medicamento"
                        }
                        style={{
                          fontFamily: "Muli_400Regular",
                          fontSize: 15,
                          lineHeight: 18,
                          color: theme.text,
                          flex: 1,
                          borderWidth: 1,
                          borderColor: theme.innearColor,
                        }}
                        onChangeText={(e) => {
                          onSetObservationsPractices(index, e);
                        }}
                        multiline={true}
                        editable={true}
                        maxLength={100}
                      />
                    </View>
                  </>
                );
              })}
            </ScrollView>
          ) : null}
          {practices && practices.length && practices.length > 1 ? (
            <ButtonText
              title={locales_es.clean}
              style={[styles.button, { marginVertical: 20 }]}
              onPress={() => {
                setPractices([]);
                setComboSelected([]);
              }}
            />
          ) : null}

          {newPractices && newPractices.length ? (
            <View
              style={{
                borderBottomColor: Colors.WhiteGray,
                borderBottomWidth: 0.5,
              }}
            />
          ) : null}
          {newPractices && newPractices.length ? (
            <ScrollView style={[styles.containerBtnPracticesContent]}>
              {newPractices.map((pr, index) => {
                return (
                  <>
                    <TagItem
                      title={pr.name}
                      onPressX={() => onPressNewPracticeX(index)}
                      iconColor={{ tintColor: "white" }}
                    />

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: pr.is_censionated
                          ? "space-between"
                          : "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <View
                        style={{
                          alignSelf: "flex-start",
                          marginTop: 8,
                        }}
                      >
                        <AccountItem
                          style={styles.switch}
                          name={"Práctica sesionada"}
                          isToggle={true}
                          onPress={() => onPressCensionatedNewPractice(index)}
                          switchValue={pr.is_censionated}
                        />
                      </View>

                      {pr.is_censionated && (
                        <View
                          style={{
                            alignSelf: "center",

                            marginLeft: "-10%",
                            marginTop: 5,
                            transform: [{ scale: 0.5 }],
                          }}
                        >
                          <InputSpinner
                            min={1}
                            step={1}
                            colorMax={Colors.Tomato}
                            colorMin={Colors.TealBlue}
                            color={Colors.TealBlue}
                            value={Number(pr.quantity)}
                            skin="modern"
                            onChange={(num: any) =>
                              setQuantityNewPractice(index, num)
                            }
                          />
                        </View>
                      )}
                    </View>

                    <Text
                      size={16}
                      lineHeight={24}
                      style={{
                        marginLeft: 24,
                        marginBottom: 10,
                        marginTop: 10,
                      }}
                    >
                      {locales_es.observations}
                    </Text>

                    <View
                      style={{
                        height: 100,
                        width: "90%",
                        alignSelf: "center",
                        marginBottom: 20,
                      }}
                    >
                      <TextInput
                        placeholderTextColor={theme.placeholder}
                        value={pr.observations}
                        placeholder={
                          "Añada aquí las observaciones para el medicamento"
                        }
                        style={{
                          fontFamily: "Muli_400Regular",
                          fontSize: 15,
                          lineHeight: 18,
                          color: theme.text,
                          flex: 1,
                          borderWidth: 1,
                          borderColor: theme.innearColor,
                        }}
                        onChangeText={(e) => {
                          onSetObservationsNewPractices(index, e);
                        }}
                        multiline={true}
                        editable={true}
                        maxLength={100}
                      />
                    </View>
                  </>
                );
              })}
            </ScrollView>
          ) : null}
          {newPractices && newPractices.length && newPractices.length > 1 ? (
            <ButtonText
              title={locales_es.clean}
              style={[styles.button, { marginVertical: 20 }]}
              onPress={() => setNewPractices([])}
            />
          ) : null}
        </Layout>
        {renderDiagnostic(
          `${locales_es.diagnosis} (${locales_es.optional})`,
          locales_es.addPrescriptionDiagnosis
        )}
        {renderFreePrescription(
          "Notas adicionales (Opcional)",
          "Agregue aquí aclaraciones si lo cree necesario"
        )}
        {renderIndications(
          `${locales_es.indications} (${locales_es.optional})`,
          locales_es.addPrescriptionIndications
        )}
      </>
    );
  }, [
    practices,
    prescriptionProfileId,
    errors,
    text,
    indications,
    newPractices,
    comboSelected,
    diagnostic,
    initDiagnostic,
    diagnosticCod,
  ]);

  const renderFeatureItem = useCallback(({ item, index }) => {
    const oddStyle = index % 2 === 0 ? { marginRight: 16 } : null;
    return <FeatureItem style={[oddStyle]} {...item} />;
  }, []);

  const imgStyle = { width: "50%", marginTop: 20 };

  const FEATURE = [
    {
      id: 4,
      img: FEATURE_ICON.healthTip,
      title: "Texto libre",
      route: Routes.AddPatient,
      imgStyle,
      onPress: () => setPrescriptionStyle(PRESCRIPTION_STYLE_FREE),
    },
    {
      id: 7,
      img: FEATURE_ICON.healthGuide,
      title: "Sistema de prácticas médicas",
      imgStyle,
      onPress: () => setPrescriptionStyle(PRESCRIPTION_STYLE_PRACTICES),
    },
  ];

  const handleScrollToOverlay = () => {
    const overlayElement = document.getElementById("idOverlayCertificate");
    if (overlayElement) {
      overlayElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    handleScrollToOverlay();
  }, [overlay]);

  return (
    <>
      {overlay && (
        <Overlay
          id="idOverlayCertificate"
          style={{ left: 0, top: 0, height: 1250 }}
        >
          {overlay}
        </Overlay>
      )}
      {congrats && (
        <FullScreenGreenCheck
          text={locales_es.createdCertificateSuccessfully}
        />
      )}
      {loading && <FullScreenLoading text={loadingText} />}
      <View style={styles.container}>
        <View style={styles.content}>
          {showLicenseModal && (
            <Modal visible={true} transparent={true}>
              <View
                style={{
                  backgroundColor: Colors.Black68,
                  ...StyleSheet.absoluteFillObject,
                  paddingHorizontal: 24,
                  justifyContent: "center",
                  zIndex: 1000,
                }}
              >
                <Layout
                  style={{
                    borderRadius: 16,
                    paddingHorizontal: 24,
                    paddingVertical: 32,
                    width: "50%",
                    maxWidth: 640,
                    textAlign: "center",
                    margin: "auto",
                  }}
                >
                  <Text center size={17} lineHeight={20} bold>
                    {locales_es.RequieredDataLicenceModal.title}
                  </Text>
                  <Text center size={13} lineHeight={22} marginTop={16}>
                    {locales_es.RequieredDataLicenceModal.subtitle}
                  </Text>

                  <ButtonLinear
                    white
                    title={locales_es.RequieredDataLicenceModal.primaryAction}
                    onPress={() => onIncompleteProfile()}
                    style={{ width: "60%", alignSelf: "center", marginTop: 15 }}
                  />
                </Layout>
              </View>
            </Modal>
          )}
          <View style={styles.headerWrapper}>
            <Text size={38} lineHeight={36} bold>
              Crear Prescripción
            </Text>
          </View>
          <View style={styles.user}>
            <TouchableOpacity onPress={selectPatient}>
              {patientId ? (
                <>
                  {userData && (
                    <View style={styles.userDataWrapper}>
                      <View style={styles.userName}>
                        <View>
                          <Image
                            source={
                              userData.full_profile_image
                                ? { uri: userData.full_profile_image }
                                : avatarProfileIcon
                            }
                            style={{ width: 50, height: 50, borderRadius: 25 }}
                          />
                        </View>
                        <View>
                          <Text
                            size={20}
                            lineHeight={24}
                            semibold
                            marginRight={32}
                          >
                            {userData.name} {userData.lastname}
                          </Text>
                          {userData.gender && userData.gender.name && (
                            <Text
                              size={18}
                              lineHeight={22}
                              bold
                              marginRight={32}
                            >
                              {userData.gender.name}
                            </Text>
                          )}
                        </View>
                      </View>
                      <ButtonLinear
                        white
                        styleButton={{ width: 200 }}
                        title={locales_es.createCertificate}
                        onPress={onSend}
                        disabled={!prescriptionStyle}
                      />
                    </View>
                  )}
                </>
              ) : (
                <SelectPatient
                  style={{
                    borderWidth: 1,
                    borderColor: errors.includes("patientId")
                      ? theme.lineErrorColor
                      : theme.innearColor,
                  }}
                />
              )}
            </TouchableOpacity>
          </View>
          <View style={styles.cardWrapper}>
            <View style={styles.cardItem}>
              <Card
                header={{
                  icon: SOURCE_ICON.exp,
                  title:
                    locales_es.configuration_page.prescription_profiles_info,
                  action: (
                    <TouchableOpacity
                      onPress={() => {
                        EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
                          title: locales_es.prescriptionProfilesInfo.title,
                          message: locales_es.prescriptionProfilesInfo.content,
                        });
                      }}
                    >
                      <Image
                        source={{
                          uri: SOURCE_ICON.help,
                          width: 25,
                          height: 25,
                        }}
                        style={styles.cardAction}
                      />
                    </TouchableOpacity>
                  ),
                }}
              >
                {typeof prescriptionProfiles === "undefined" && (
                  <ActivityIndicator />
                )}
                {prescriptionProfiles === null && (
                  <Text size={16}>
                    {locales_es.configuration_page.no_prescription_profile}
                  </Text>
                )}
                {prescriptionProfiles && (
                  <View style={styles.prescriptionProfileWrapper}>
                    <PrescriptionProfiles
                      {...userData}
                      itemStyle={{ borderRadius: 0 }}
                      removeTitle
                      prescriptionProfiles={prescriptionProfiles}
                      onProfileSelect={onPrescriptionProfileSelect}
                      selectedProfileId={prescriptionProfileId}
                      style={
                        errors.includes("prescriptionProfileId")
                          ? styles.sectionError
                          : null
                      }
                      editable={
                        (userData && userData.clinic_id == null) || !userData
                      }
                      // editable={false}
                    />
                  </View>
                )}
              </Card>

              {userData && insurancePermissionFlag && (
                <View>
                  <PatientMedicalInsurancesList
                    patientMedicalInsurances={medicalInsurances}
                    onMedicalInsuranceSelect={onMedicalInsuranceSelect}
                    edit={false}
                    hidenModalChange={true}
                  />
                </View>
              )}

              <Card
                header={{
                  title:
                    dates.length > 1
                      ? locales_es.certificatesDates
                      : locales_es.certificateDate,
                  icon: SOURCE_ICON.calendar,
                }}
              >
                <View
                  style={{
                    flex: 1,
                  }}
                >
                  {dates && dates.length
                    ? dates.map((_date: any, index: number) => {
                        return (
                          <View key={"datePicker-" + index}>
                            <FormNewDatePicker
                              withPortal={true}
                              portalId={"datePicker-" + index}
                              value={_date}
                              placeholder={locales_es.date}
                              // required={true}
                              handleChange={(date: any) => {
                                setDateArray(date, index);
                              }}
                              minDate={minDate}
                            />
                          </View>
                        );
                      })
                    : null}
                </View>
              </Card>

              {prescriptionStyle && enabledSelectionType ? (
                <Card>
                  <ButtonText
                    title="Cambiar tipo de prescripción"
                    style={styles.button}
                    onPress={() => clearPrescriptionType()}
                  />
                </Card>
              ) : null}
            </View>
            <View style={styles.cardItem}>
              {prescriptionStyle === "" ? (
                <View>
                  <Text
                    marginTop={getStatusBarHeight() + 24}
                    marginHorizontal={12}
                    bold
                    center
                    size={24}
                    lineHeight={28}
                  >
                    Elija tipo de prescripción
                  </Text>
                  <View
                    style={[
                      styles.topView,
                      Theme.flexRowSpace,
                      { marginBottom: 30, justifyContent: "center" },
                    ]}
                  >
                    <Text size={15} lineHeight={18} center>
                      Puede hacer una prescripción con texto libre, o puede
                      utilizar nuestro nuevo sistema de prácticas
                    </Text>
                  </View>
                  <View
                    style={[{ flexDirection: "row", justifyContent: "center" }]}
                  >
                    {FEATURE.map((feature, index) =>
                      renderFeatureItem({ item: feature, index })
                    )}
                  </View>
                </View>
              ) : prescriptionStyle === PRESCRIPTION_STYLE_FREE ? (
                <>
                  {renderFreePrescription(
                    "Texto Libre",
                    "Aquí puede generar recetas personalizadas, prescripción libre, indicaciones, etc."
                  )}
                  {renderDiagnostic(
                    `${locales_es.diagnosis} (${locales_es.optional})`,
                    locales_es.addPrescriptionDiagnosis
                  )}
                  {renderIndications(
                    `${locales_es.indications} (${locales_es.optional})`,
                    locales_es.addPrescriptionIndications
                  )}
                </>
              ) : (
                renderPracticesPrescription()
              )}
            </View>
          </View>
        </View>
      </View>
    </>
  );
});

export default AddCertificate;

const styles = StyleSheet.create({
  topView: {
    marginTop: 32,
    marginBottom: 12,
    paddingHorizontal: 24,
  },
  button: {
    borderWidth: 0,
  },
  containerBtnPractices: {
    // padding: 24,
    borderRadius: 16,
    ...Theme.shadow,
    // ...Theme.flexRow,
  },
  containerBtnPracticesContent: {
    padding: 24,
  },
  successImage: {
    width: scale(160, true),
    height: scale(160, true),
    marginBottom: scale(55, true),
  },

  container: { flex: 1, minHeight: "100%" },
  content: {
    minHeight: "100%",
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: getStatusBarHeight(),
  },
  headerWrapper: { flexDirection: "row", gap: 16, alignItems: "center" },
  user: {
    marginTop: 16,
    maxWidth: 1224,
  },
  userDataWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  userName: {
    display: "flex",
    flexDirection: "row",
    gap: 16,
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "row",
    marginTop: 24,
    gap: 24,
    flexWrap: "wrap",
  },
  cardItem: {
    flex: 1,
    gap: 24,
    minWidth: 300,
    maxWidth: 600,
    height: "fit-content",
  },
  cardAction: { tintColor: "#00c1c1", color: "#00c1c1" },
  prescriptionProfileWrapper: {
    overflow: "auto",
    marginLeft: -24,
    marginRight: -24,
    marginTop: -16,
    maxHeight: "100%",
  },
  switch: {
    ...Theme.flexRowSpace,
    paddingTop: 12,
    paddingBottom: 12,
    left: 10,
    paddingRight: 20,
    display: "flex",
    flexDirection: "row",
    gap: 20,
    witdh: "50%",
    fontSize: 10,
  },
  switchIconStyle: {
    tintColor: Colors.DodgerBlue,
    height: 20,
    width: 20,
    maxHeight: 20,
  },
});
