import React from "react";
import {
  View,
  StyleSheet,
  Image,
  TouchableWithoutFeedback,
  Animated,
  Easing,
  TouchableOpacity,
} from "react-native";
import Text from "../Text";
import { Colors } from "../../configs";
import iconArrowDown from "../../images/ic_arr_down.png";
import iconTrash from "../../images/ic_trash.png";
import iconEdit from "../../images/ic_edit.png";
import { height, width } from "../../configs/Const";
import { useRef, useState } from "react";
import { color, set } from "react-native-reanimated";
import Collapsible from "react-collapsible";
import Line from "../Layout/Line";
import AccountItem from "../../components/AccountItem/Index";
import Theme from "../../style/Theme";
import { useEffect } from "react";
interface Props {
  style?: any;
  medicalLicense?: any;
  deleteMedicalLicense?: any;
  onEditMedicalLicense?: any;
  onSelect?: boolean;
  handlerSelect?: any;
  medicalLicenseSelected: any;
}

function MedicalLicensesItem(props: Props) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [emptyField, setEmptyField] = useState(false);
  const medicalLicenseData = { ...props.medicalLicense, edit: true };

  const handleSwitchChange = (newValue: any) => {
    if (newValue) {
      props.handlerSelect(props.medicalLicense.id);
    } else if (props.medicalLicense.id === props.medicalLicenseSelected) {
      props.handlerSelect(null);
    }
  };

  const animatedIconRotation = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(animatedIconRotation, {
      duration: 150,
      easing: Easing.linear,
      toValue: isCollapsed ? 0 : 180,
      useNativeDriver: true,
    }).start();
  }, [isCollapsed]);

  const country =
    props.medicalLicense && props.medicalLicense.country_id
      ? props.medicalLicense.country_id
      : "";

  const type =
    props.medicalLicense && props.medicalLicense.medical_licenses_type
      ? props.medicalLicense.medical_licenses_type.code
      : "";

  const typeLicense =
    props.medicalLicense && props.medicalLicense.medical_licenses_type
      ? props.medicalLicense.medical_licenses_type?.description
      : "Matrícula";

  const code = props.medicalLicense ? props.medicalLicense.identification : "";

  const jurisdiction =
    props.medicalLicense &&
    props.medicalLicense.medical_licenses_jurisdiction &&
    props.medicalLicense.medical_licenses_type.code !== "MN"
      ? `(${props.medicalLicense.medical_licenses_jurisdiction?.description})`
      : "(CABA)";

  const validateEmptyField = () => {
    if (type === "" || code === "" || jurisdiction === "" || code === "") {
      setEmptyField(true);
    } else {
      setEmptyField(false);
    }
  };

  useEffect(() => {
    validateEmptyField();
  }, [props]);

  return (
    <View style={{ height: "auto" }}>
      <TouchableWithoutFeedback>
        <View style={styles.container}>
          <View style={{ width: "70%" }}>
            <h5
              style={{
                fontSize: 18,
              }}
            >
              {" "}
              {type} {code}{" "}
              {props.medicalLicense.source == "sisa" && country !== 2
                ? ""
                : jurisdiction}
            </h5>
            {props.medicalLicense.source == "sisa" && (
              <View
                style={{
                  position: "absolute",
                  top: 46,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  width: "100%",
                  color: Colors.TealBlue,
                }}
              >
                <Text size={12} color={Colors.TealBlue}>
                  Sincronizada SISA
                </Text>
              </View>
            )}
          </View>
          {!props.onSelect && (
            <View style={styles.iconContainer}>
              <TouchableOpacity
                style={styles.IconStyle}
                onPress={() => props.onEditMedicalLicense(medicalLicenseData)}
              >
                <Image
                  source={iconEdit}
                  style={{
                    tintColor: Colors.TealBlue,
                    minHeight: 18,
                    minWidth: 18,
                    marginTop: 4,
                  }}
                  resizeMode="contain"
                />
              </TouchableOpacity>
              {props.medicalLicense.source == "manual" && (
                <TouchableOpacity
                  style={styles.IconStyle}
                  onPress={() =>
                    props.deleteMedicalLicense(props.medicalLicense)
                  }
                >
                  <Image
                    source={iconTrash}
                    style={{
                      tintColor: Colors.Tomato,
                      minHeight: 25,
                      minWidth: 25,
                    }}
                    resizeMode="contain"
                  />
                </TouchableOpacity>
              )}
              <TouchableWithoutFeedback
                style={styles.IconStyle}
                onPress={() => {
                  setIsCollapsed(!isCollapsed);
                }}
              >
                <Animated.View
                  style={{
                    transform: [
                      {
                        rotate: animatedIconRotation.interpolate({
                          inputRange: [0, 180],
                          outputRange: ["0deg", "-180deg"],
                        }),
                      },
                    ],
                  }}
                >
                  <Image
                    source={iconArrowDown}
                    style={{
                      tintColor: isCollapsed
                        ? Colors.MediumTurquoise
                        : Colors.Platinum,
                      minHeight: "25px",
                      minWidth: "25px",
                    }}
                    resizeMode="contain"
                  />
                </Animated.View>
              </TouchableWithoutFeedback>
            </View>
          )}
          {props.onSelect && (
            <View style={styles.iconContainer}>
              <TouchableOpacity
                onPress={() => props.handlerSelect(props.medicalLicense.id)}
              >
                <AccountItem
                  style={styles.switch}
                  isToggle={true}
                  onValueChange={handleSwitchChange}
                  switchValue={
                    props.medicalLicense.id === props.medicalLicenseSelected
                  }
                />
              </TouchableOpacity>
            </View>
          )}
          {props.onSelect && emptyField && (
            <View style={styles.iconContainer}></View>
          )}
        </View>
      </TouchableWithoutFeedback>
      <View style={{ flex: 1, flexDirection: "column", margin: 0 }}>
        <Collapsible trigger open={!isCollapsed}>
          {props.medicalLicense &&
            props.medicalLicense &&
            props.medicalLicense.identification && (
              <View>
                <Text marginTop={24} marginLeft={24} size={13} lineHeight={16}>
                  Número de Matricula
                </Text>
                <Text
                  marginTop={8}
                  marginLeft={24}
                  bold
                  size={15}
                  lineHeight={18}
                >
                  {props.medicalLicense.identification}
                </Text>
              </View>
            )}
          {props.medicalLicense &&
            props.medicalLicense &&
            props.medicalLicense.medical_licenses_jurisdiction &&
            props.medicalLicense.medical_licenses_jurisdiction?.description &&
            type !== "MN" && (
              <View>
                <Text marginTop={24} marginLeft={24} size={13} lineHeight={16}>
                  Jurisdicción
                </Text>
                <Text
                  marginTop={8}
                  marginLeft={24}
                  bold
                  size={15}
                  lineHeight={18}
                >
                  {
                    props.medicalLicense.medical_licenses_jurisdiction?.description
                  }
                </Text>
              </View>
            )}

          <View>
            <Text marginTop={24} marginLeft={24} size={13} lineHeight={16}>
              Tipo de Matricula
            </Text>
            <Text
              marginTop={8}
              marginBottom={20}
              marginLeft={24}
              bold
              size={15}
              lineHeight={18}
            >
              {typeLicense}
            </Text>
          </View>
        </Collapsible>
      </View>
      <Line height={1} marginTop={0} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "space-between",
    marginHorizontal: 24,
    flexDirection: "row",
    alignItems: "center",
  },
  IconStyle: {
    display: "flex",
    flexDirection: "row",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 15,
    justifyContent: "flex-end",
    width: "30%",
  },
  switch: {
    ...Theme.flexRowSpace,
    paddingTop: 12,
    paddingBottom: 12,
    left: 10,
    paddingRight: 20,
  },
});

export default MedicalLicensesItem;
