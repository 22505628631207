import React, { useState, useCallback, useEffect, useRef } from "react";
import moment from "moment";
import { Routes } from "../../../configs";
import locales_es from "../../../locales/es";
import { useHistory } from "react-router-dom";
import Helpers from "../../../modules/helpers";
import TranslationService from "../../../locales";
import { useTheme } from "../../../configs/Theme";
import APIService from "../../../modules/apiService";
import AuthService from "../../../modules/authService";
import { showModalInfo } from "../../../modules/ViewHelpers";
import CountryService from "../../../modules/CountryService";
import { EventRegister } from "../../../modules/EventRegister";
import PrescriptionService from "../../../modules/PrescriptionService";
import useModalWithKeyboard from "../../../hooks/useModalWithKeyboard";
import { uploadIdentificationImage } from "../../../modules/ApiHelpers";
import {
  EVENT_RELOAD_PRESCRIPTION_PROFILES,
  EVENT_RELOAD_MEDICAL_LICENSE,
} from "../../../constants/Events";

const useWorkProfilePage = () => {
  const history = useHistory();
  const params = history.location.state;

  const prescriptionService = new PrescriptionService();
  const prescriptionIdFromStorage = prescriptionService.get();

  let {
    prescriptionProfileId,
    stepTitle,
    firstProfile,
    hasSignup,
    stepNumber,
  }: any = params ? params : null;

  prescriptionProfileId = prescriptionProfileId
    ? prescriptionProfileId
    : prescriptionProfileId;

  const api = new APIService();
  const helpers = new Helpers();
  const auth = new AuthService();
  const translationService = new TranslationService();

  const [name, setName] = useState<string>("");
  const [load, setLoad] = useState<boolean>(false);
  const [sisa, setSisa] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>({});
  const [countryId, setCountryId] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [hiddenText, setHiddenText] = useState<any>(true);
  const [clinicData, setClinicData] = useState<any>(null);
  const [translations, setTranslations] = useState<any>(null);
  const [medicalsLicenses, setMedicalsLicenses] = useState<any>(null);
  const [loadingLicense, setLoadingLicense] = useState<boolean>(false);
  const [loadingSyncSisa, setLoadingSyncSisa] = useState<boolean>(false);
  const [medicalLicenseValue, setMedicalLicenseValue] = useState<any>({});
  const [disabledSyncSisa, setDisabledSyncSisa] = useState<boolean>(false);
  const [medicalLicenseSelected, setMedicalLicenseSelected] =
    useState<any>(null);
  const [medicalLicenseConfiguration, setMedicalLicenseConfiguration] =
    useState<any>([]);
  const [
    prescriptionProfileAdditionalFields,
    setPrescriptionProfileAdditionalFields,
  ] = useState<any>([]);

  const onSubmit = useCallback(() => {
    if (!medicalLicenseSelected || medicalLicenseSelected.length === 0) {
      showModalInfo(
        locales_es.infoModal.title,
        "Elija una Matricula Profesional"
      );
      return;
    } else {
      onSubmitConfirm();
    }
  }, [
    prescriptionProfileAdditionalFields,
    medicalLicenseConfiguration,
    medicalLicenseValue,
    medicalLicenseSelected,
  ]);

  const onSubmitConfirm = () => {
    if (!medicalLicenseSelected || medicalLicenseSelected.length === 0) {
      showModalInfo(
        locales_es.infoModal.title,
        "Elija una Matricula Profesional"
      );
      return;
    }

    const objData = {
      medical_license_id: medicalLicenseSelected,
      prescription_profile_id: prescriptionProfileId,
    };

    if (prescriptionProfileId) {
      api
        .putPrescriptionProfile(prescriptionProfileId, objData)
        .then(() => {
          sendEvent();
          setLoading(false);
          history.push(`/${Routes.OtherInformation}`, {
            prescriptionProfileId,
            medicalLicenseId: medicalLicenseSelected,
            hasTwoSteps: false,
            stepTitle:
              clinicData && !clinicData.paylink_enabled
                ? "¡Último Paso!"
                : "Paso 2 de 3",
            stepNumber: 2,
            hasSignup: !!hasSignup,
            firstProfile,
          });
        })
        .catch((err: any) => {
          showModalInfo(locales_es.errorModal.title, helpers.getErrorMsg(err));
          setLoading(false);
        });
    } else {
      history.push(`/${Routes.OtherInformation}`, {
        hasTwoSteps: false,
        medicalLicenseId: medicalLicenseSelected,
        hasSignup: !!hasSignup,
        stepTitle:
          clinicData && !clinicData.paylink_enabled
            ? "¡Último Paso!"
            : "Paso 2 de 3",
        stepNumber: 2,
        firstProfile,
      });
    }
  };

  const AddMedicalLicense = () => {
    history.push(`/${Routes.AddMedicalLicense}`, {
      from: history.location.pathname,
      firstProfile,
    });
  };

  const sendEvent = () => {
    EventRegister.emit(EVENT_RELOAD_PRESCRIPTION_PROFILES);
  };

  useEffect(() => {
    if (prescriptionProfileId) {
      api
        .getPrescriptionProfile(prescriptionProfileId)
        .then((res2: any) => {
          setName(res2.data.name);
          setMedicalLicenseSelected(res2.data.medical_license_id);
        })
        .catch((err2: any) => {
          showModalInfo(locales_es.infoModal.title, helpers.getErrorMsg(err2));
        });
    }

    translationService.translate().then((translation) => {
      setTranslations(translation);
    });

    setLoadingLicense(true);

    auth
      .getRemoteUserData()
      .then((res: any) => {
        if (res && res.data && res.data.user) {
          setCountryId(res.data.user.country_id);
          setUserData(res.data.user);

          api
            .getMedicalsLicenses()
            .then((res4: any) => {
              setLoadingLicense(false);

              if (res4 && res4.data && res4.data.length > 0) {
                setMedicalsLicenses(res4.data);
              }

              if (
                res.data.user.last_sisa_verificacion == null ||
                !res.data.user.last_sisa_verificacion ||
                res.data.user.last_sisa_verificacion == "0000-00-00 00:00:00"
              ) {
                setDisabledSyncSisa(false);
                return;
              }

              const today = moment().format("YYYY-MM-DD HH:mm:ss");
              const lastSync = moment(
                res.data.user.last_sisa_verificacion
              ).format("YYYY-MM-DD HH:mm:ss");

              const diff = moment(today).diff(moment(lastSync), "hours");

              if (diff > 24 || !lastSync || Number.isNaN(diff)) {
                setDisabledSyncSisa(false);
              } else {
                setDisabledSyncSisa(true);
              }
            })
            .catch((err4: any) => {
              setLoadingLicense(false);
              showModalInfo(
                locales_es.infoModal.title,
                helpers.getErrorMsg(err4)
              );
            });
        }
      })
      .catch((err) => {
        setLoadingLicense(false);
        console.log(err);
      });
  }, [load]);

  useEffect(() => {
    // Check if practices are enabled (enabled_certificates)
    const countryService = new CountryService();
    countryService
      .getData()
      .then((res: any) => {
        if (res && res.code === "ar") {
          setHiddenText(false); // Hay un texto que por ahora se muestra solo en ARG
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const onSyncSisa = () => {
    if (loadingSyncSisa) return;
    setLoadingSyncSisa(true);
    api
      .syncSISA(userData.identification)
      .then((res: any) => {
        setSisa(true);
        setDisabledSyncSisa(true);
        setLoadingSyncSisa(false);
        showModalInfo(locales_es.medicalLicenseSync.title, res.message);

        EventRegister.emit(EVENT_RELOAD_MEDICAL_LICENSE);
      })
      .catch((err: any) => {
        setLoadingSyncSisa(false);
        showModalInfo(locales_es.errorModal.title, helpers.getErrorMsg(err));
      });
  };

  useEffect(() => {
    const listener = EventRegister.addEventListener(
      EVENT_RELOAD_MEDICAL_LICENSE,
      (data: any) => {
        loadMedicalLicenses();
      }
    );

    return function cleanup() {
      EventRegister.removeEventListener(listener);
    };
  });

  const loadMedicalLicenses = () => {
    setLoadingLicense(true);
    api
      .getMedicalsLicenses()
      .then((res: any) => {
        setLoadingLicense(false);
        if (res && res.data && res.data.length > 0) {
          setMedicalsLicenses(res.data);
        }
      })
      .catch((err: any) => {
        setLoadingLicense(false);
        showModalInfo(locales_es.infoModal.title, helpers.getErrorMsg(err));
      });
  };

  const {
    visible: visibleAdditionalFieldMenuOption,
    open: openAdditionalFieldMenuOption,
    close: closeAdditionalFieldMenuOption,
  } = useModalWithKeyboard(false);

  const handleMedicalLicenseChange = (id: any) => {
    setMedicalLicenseSelected(id);
  };

  return {
    name,
    onSubmit,
    onSyncSisa,
    stepNumber,
    clinicData,
    loadingLicense,
    medicalsLicenses,
    disabledSyncSisa,
    AddMedicalLicense,
    medicalLicenseSelected,
    handleMedicalLicenseChange,
  };
};

export default useWorkProfilePage;
